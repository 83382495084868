import React, { createContext, useState } from 'react'

import { Signer } from '@polkadot/api/types'

import { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'

export type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'

type PolkadotState = {
  enabled: boolean
  active: InjectedAccountWithMeta | null
  signer: Signer | null
  accounts: InjectedAccountWithMeta[]
  connect: () => Promise<void>
  setActive: (account: InjectedAccountWithMeta) => Promise<void>
}

const initialState: PolkadotState = {
  enabled: false,
  active: null,
  signer: null,
  accounts: [],
  connect: () => Promise.resolve(),
  setActive: () => Promise.resolve()
}

const PolkadotContext = createContext<PolkadotState>(initialState)

export const PolkadotProvider = ({ children }: { children: any }) => {
  const [enabled, setEnabled] = useState(false)
  const [accounts, setAccounts] = useState<InjectedAccountWithMeta[]>([])
  const [active, setActiveAccount] = useState<InjectedAccountWithMeta | null>(
    null
  )
  const [signer, setSigner] = useState<Signer | null>(null)

  const connect = async () => {
    if (window) {
      // dynamic loading as package relies on `window` global
      const ExtensionDApp = await import('@polkadot/extension-dapp')
      const { web3Accounts, web3Enable } = ExtensionDApp
      const extensions = await web3Enable('supercharge')

      if (extensions.length > 0) {
        const _accounts = await web3Accounts()
        if (_accounts.length > 0) {
          setAccounts(_accounts)
        }
      }
      setEnabled(true)
    }
  }

  const setActive = async (account: InjectedAccountWithMeta) => {
    if (window) {
      const ExtensionDApp = await import('@polkadot/extension-dapp')
      const { web3FromAddress } = ExtensionDApp
      setActiveAccount(account)
      const injector = await web3FromAddress(account.address)
      setSigner(injector.signer)
    }
  }

  return (
    <PolkadotContext.Provider
      value={{
        enabled,
        active,
        signer,
        accounts,
        connect,
        setActive
      }}
    >
      {children}
    </PolkadotContext.Provider>
  )
}

export default PolkadotContext
