import React, { createContext, useState } from 'react'
import { ethers } from 'ethers'
import { Network } from '@ethersproject/networks'

type EthersState = {
  connect: () => Promise<void>
  address: string
  signer: ethers.Signer | null
  error: string
  network: Network | null
  enabled: boolean
  loading?: boolean
  accounts?: string[]
  provider?: ethers.providers.JsonRpcProvider | null
}

const initialState: EthersState = {
  connect: () => Promise.resolve(),
  address: '',
  signer: null,
  error: '',
  network: null,
  enabled: false
}

const EthersContext = createContext<EthersState>(initialState)

export const EthersProvider = ({ children }: { children: any }) => {
  const [address, setAddress] = useState<string>('')
  const [provider, setProvider] =
    useState<ethers.providers.JsonRpcProvider | null>(null)
  const [signer, setSigner] = useState<ethers.Signer | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState('')
  const [network, setNetwork] = useState<Network | null>(null)
  const [enabled, setEnabled] = useState(false)
  const [accounts, setAccounts] = useState<string[]>([])

  const connect = async () => {
    // ethers.js iniitialization
    const { ethereum } = window as any
    if (ethereum) {
      let accounts

      // handle user dismissing modal
      try {
        accounts = (await ethereum.request({
          method: 'eth_requestAccounts'
        })) as string[]
      } catch (error) {
        console.error(error)

        const code = (error as any).code
        if (code === -32002) {
          // User rejected request
          setError(
            'User rejected connect request. Please refresh page to try again'
          )
          return
        }

        throw error
      }

      const _provider = new ethers.providers.Web3Provider(ethereum)
      const _signer = await _provider.getSigner()
      const _address = await _signer.getAddress()
      const _network = await _provider.getNetwork()

      setAccounts(accounts)
      setProvider(_provider)
      setSigner(_signer)
      setAddress(_address)
      setNetwork(_network)

      setEnabled(true)

      ethereum.on('accountsChanged', (accounts: string[]) => {
        setAddress(accounts[0])
        setAccounts(accounts)
      })

      ethereum.on('chainChanged', () => {
        window.location.reload()
      })
    }

    setLoading(false)
  }

  return (
    <EthersContext.Provider
      value={{
        enabled,
        address,
        connect,
        network,
        error,
        signer,
        provider,
        loading,
        accounts
      }}
    >
      {children}
    </EthersContext.Provider>
  )
}

export default EthersContext
